<div class="user-menu-container" [ngClass]="{ active: isMenuActive }">
	<div class="user" (click)="toggleDropdown()">
		<img [src]="'/assets/images/anon.png'" alt="" class="rounded-circle">
		<div class="text-truncate">
			{{ 'Noname' }}
		</div>

		<div class="spacer"></div>

		<div class="caret" [ngClass]="{ '--rotate-180deg': isMenuActive }">
			<i class="fas fa-caret-down ml-2"></i>
		</div>
	</div>

	<div class="user-dropdown">
		<div class="dropdown-content">
			<a class="user-menu-button" routerLink="/app/profile/{{ '' }}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
				<i class="fa fa-user user-menu-icon"></i>
				<span>Profile</span>
			</a>

			<a class="user-menu-button" routerLink="/app/account" routerLinkActive="active">
				<i class="fa fa-cog user-menu-icon"></i>
				<span>Account Settings</span>
			</a>

			<a class="user-menu-button" routerLink="/app/data-sources" routerLinkActive="active">
				<i class="leet-datasources user-menu-icon"></i>
				<span>Data Sources</span>
			</a>

			<a class="user-menu-button" routerLink="/app/support" routerLinkActive="active">
				<i class="leet-support user-menu-icon"></i>
				<span>Support</span>
			</a>

			<div class="user-menu-button center">
				<a class="discord-button" href="https://discord.gg/UNygC8BAVg" target="_blank">
					<i class="fab fa-discord user-menu-icon"></i>
					<span>Join our Discord</span>
				</a>
			</div>

			<button class="user-menu-button logout-button" (click)="logout()">
				<i class="fa fa-sign-out-alt user-menu-icon"></i>
				<span>Logout</span>
			</button>
		</div>
	</div>
</div>
