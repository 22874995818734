import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { offset } from '@popperjs/core';
import { Subject } from 'rxjs';
import { GameFiltersDTO } from '../components/game-filters/game-filters.component';
import { QueueType } from './queue.service';

export interface BanDTO {
	championId: number;
	team: string;
}

// TODO move this to independent file:
export type ParticipantDTO = {
	puuid: string;
	items: number[];
	summonerSpells: string[];
	championId: number;
	championName: string;
	name: string;
	championAssets: {
		icon: string;
		splash: string;
	};
	role: string;
	runes: {
		primarySelectionId: number;
		primarySelections: number[];
		secondarySelectionId: number;
		secondarySelections: number[];
		statSelections: number[];
	};
	kills: number;
	deaths: number;
	assists: number;
	totalDamageDealt: number;
	totalGoldEarned: number;
	totalGoldSpent: number;
	totalHeal: number;
	totalDamageTaken: number;
	magicDamageTaken: number;
	physicalDamageTaken: number;
	trueDamageTaken: number;
	damageSelfMitigated: number;
	wardsPlaced: number;
	wardsDestroyed: number;
	totalDamageToTurrets: number;
	totalDamageToObjectives: number;
	visionScore: number;
	ccScore: number;
	csPerMinute: number;
	csScore: number;
	goldPerMinute: number;
	damagePerMinute: number;
	team?: string;
	timeline: {
		itemEvents: ItemEventDTO[];
	};
};

export interface ItemEventDTO {
	event_type: string;
	timestamp: number;
	item_id: number;
}

export type GameDTO = {
	id: string;
	gameDuration: number;
	gameStart: number;
	gameEnd: number;
	queueId: number;
	currentRank: {
		leaguePoints: number;
		leaguePointsDifference: number | null;
		rank: string;
		tier: string;
	};
	teams: [
		{
			bans: BanDTO[];
			team: string;
			win: boolean;
			participants: ParticipantDTO[];
		},
		{
			bans: BanDTO[];
			team: string;
			win: boolean;
			participants: ParticipantDTO[];
		},
	];
	timeline: {
		csIntervals: {
			jungle_cs: number;
			match_id: string;
			minion_cs: number;
			player_id: string;
			timestamp: number;
			total_cs: number;
		}[];
		goldIntervals: {
			blue: number;
			red: number;
			match_id: string;
			timestamp: number;
		}[];
		objectiveEvents: {
			match_id: string;
			type: string;
			sub_type: string;
			team: string;
			timestamp: number;
		}[];
	};
};
export type GameSimpleDTO = {
	id: string;
	gameDuration: number;
	gameStart: number;
	queueId: number;
	currentRank: {
		leaguePoints: number;
		leaguePointsDifference: number | null;
		rank: string;
		tier: string;
	};
	puuid: string;
	kills: number;
	deaths: number;
	assists: number;
	championId: number;
	championName: string;
	championAssets: {
		icon: string;
		splash: string;
	};
	role: string;
	win: boolean;
};

export type GamesHistoryResponse = {
	response: GameSimpleDTO[];
	meta: {
		next: number;
	};
	filters: GameFiltersDTO;
};

export type GameResponse = {
	response: GameDTO;
};

@Injectable({
	providedIn: 'root',
})
export class GamesService {
	private readonly gamesHistorySource = new Subject<GamesHistoryResponse>();
	public readonly gamesHistory$ = this.gamesHistorySource.asObservable();

	private readonly gameDetailsSource = new Subject<GameDTO>();
	public readonly gameDetails$ = this.gameDetailsSource.asObservable();

	private gameDetailsCache: { [gameId: string]: GameDTO } = {};
	private gamesHistoryCache: { [paramsHash: string]: GamesHistoryResponse } = {};

	public constructor(private readonly http: HttpClient) {}

	public reloadGamesHistory(filters?: any): void {
		const params = { ...filters };

		const paramsHash = JSON.stringify(params);

		if (this.gamesHistoryCache[paramsHash]) {
			this.gamesHistorySource.next(this.gamesHistoryCache[paramsHash]);
			return;
		}

		if (params.queueTypes) {
			params['queueTypes[]'] = params.queueTypes;
			delete params.queueTypes;
		}

		this.http.get<GamesHistoryResponse>('/api/match/v1/matches', { params }).subscribe((gamesHistory: GamesHistoryResponse) => {
			this.gamesHistoryCache = {}; // removing old cache to preserve memory
			this.gamesHistoryCache[paramsHash] = gamesHistory;
			this.gamesHistorySource.next(gamesHistory);
		});
	}

	public reloadGameDetails(id: string): void {
		if (this.gameDetailsCache[id]) {
			this.gameDetailsSource.next(this.gameDetailsCache[id]);
			return;
		}

		this.http.get<GameResponse>(`/api/match/v1/matches/${id}`).subscribe(
			(gameDetails: GameResponse) => {
				this.gameDetailsCache[id] = gameDetails.response;
				this.gameDetailsSource.next(gameDetails.response);
			},
			(error: HttpErrorResponse) => {
				this.gameDetailsSource.next(null);
			}
		);
	}
}
