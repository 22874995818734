import { AuthService } from 'src/app/auth/auth.service';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Icon } from 'src/constants';
import { Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { OnboardingCheckService } from '../../../services/onboarding-check.service';


@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	},
})
export class UserMenuComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();

	protected isAnnualRecapsSubmenuExpanded = false;
	protected isMenuActive = false;
	protected isProgressReportsSubmenuExpanded = false;
	protected user: User;

	public constructor(
		protected readonly _eref: ElementRef,
		protected readonly authService: AuthService,
		protected readonly router: Router,
		protected readonly userService: UserService,
		protected readonly onboardingCheckService: OnboardingCheckService,
	) {
		//
	}

	protected onClick(e: PointerEvent): void {
		if (!this._eref.nativeElement.contains(e.target)) { // or some similar check
			this.toggleDropdown(false);
		}
	}

	protected toggleDropdown(isActive = !this.isMenuActive): void {
		this.isMenuActive = isActive;
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	protected handleUser(user: User): void {
		this.user = user;
	}

	public ngOnInit(): void {
		this.onboardingCheckService.redirectIfNeeded();
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);

		// close menu after clicking any link
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.toggleDropdown(false));
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
