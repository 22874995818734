import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { OnboardingStatus } from '../../constants';
import { environment } from '../../environments/environment';

export {
	User,
};

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public user$ = new Subject<any>();
	public user: any;

	private readonly isBrowser: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private http: HttpClient,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		this.user$.subscribe((user) => (this.user = user));

	}

	public reloadUser(): any {
		if (!this.isBrowser) return this.user$.next(null);
		return this.http.get('/api/user/v1/userinfo').subscribe(
			(user: any) => {
				this.user$.next(user);
				return user;
			},
			(e) => {
				if (e.status === 403) AuthService.removeToken();

				window.location.href = `${environment.csFrontendBaseUrl}/auth/login?limitedTest=1`;
				return null;
			},
		);
	}

	public updateOnboarding(onboardingStatus: OnboardingStatus): Promise<any> {
		return this.http.post('/api/user/v1/update-onboarding', { onboardingStatus }, { responseType: 'text' }).toPromise();
	}
}
