<main class="container-fluid">
	<img class="platform-logo" src="../../../assets/images/leetify-logo-primary-white.png" alt="Leetify">
	<!--riot container-->
	<div [@moveUpFade]="riotTrigger" class="riot-box row align-items-end">
		<div class="text text-in-box">
			<i class="mini-text-box">Step one</i>
			<div></div>
			<i class="text-box">Connect your Riot account</i>
		</div>
		<button (click)="onRiotClick()" class="riot-button button">
				<img [src]="riotIcon" alt="Riot">
				<i class="button-text">{{ riotButtonText }}</i>
		</button>
	</div>
	<!--discord container-->
	<div [@fadeIn]="riotTrigger" [hidden]="discordIsHidden" [@fade]="discordTrigger" class="discord-box row align-items-end">
		<div class="text text-in-box">
			<i class="mini-text-box">Step two</i>
			<div></div>
			<i class="text-box">Join the Leetify Discord</i>
		</div>
		<button (click)="onDiscordClick()" class="discord-button button">
				<img [src]="discordIcon" alt="Discord">
				<i class="button-text">{{ discordButtonText }}</i>
		</button>
	</div>
	<!--finish onboarding container-->
	<div [@fadeIn]="discordTrigger" [hidden]="finishIsHidden" class="finish-box row align-items-end">
		<img class="image" src="../../../assets/images/Chibi_Ahri_Base_Tier_1%201.png" alt="ChibiAhri">
		<div class="text-in-box">
			<i class="mini-text-box">Our quest begins!</i>
			<p class="last-text-box">Thank you for being a part of the Leetify League of Legends beta! We’re thrilled to have you with us.
				Don’t forget to keep an eye on the #lol-closed-beta and #lol-announcements channels in Discord for the latest updates.
				<br>
				<br>With love,
				<br>The Leetify LoL Team</p>
			<button (click)="onViewMatchHistoryClick()" class="match-history-button">
				<i class="match-history-button-text">View your match history</i>
			</button>
		</div>
	</div>
	<!--socials-->
	<div class="socials">
		<a aria-label="Home Page" routerLink="/"><leetify-icon [icon]="Icon.HOME"></leetify-icon> </a>
		<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
			<leetify-icon [icon]="social.icon"></leetify-icon>
		</a>
	</div>
</main>
